export default function Divider() {
  return (
    <>
      <div className="container grid text-center verticalGradient p-2">
        <div className="container">
          <h1 className=".fs-1 display-1 fw-bolder pt-2">ELEANOR EDWARDS</h1>
        </div>
      </div>
    </>
  )
  // <LinearGradient
  //   colors={['#4c669f', '#3b5998', '#192f6a']}
  //   style={styles.linearGradient}
  // ></LinearGradient>
}
