/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const model = useGLTF('./E_01.glb')
  console.log(model)
  const { nodes, materials } = useGLTF('./E_01.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(1)_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(1)_(1)_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(3)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(4)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(5)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2_duplo_(5)_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2x1AndHalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2x1AndHalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2x1AndHalf_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2x1AndHalf_duplo_(2)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2x1AndHalf_duplo_(2)_(2)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x2xhalf_duplo_(1)_(1)'].geometry}
          material={nodes['1x2xhalf_duplo_(1)_(1)'].material}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x6xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x6xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['1x6xhalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2_duplo'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2_duplo_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2_duplo_(1)_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2x2_duplo'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2x2_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2x2_duplo_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2x2_duplo_(1)_(2)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2xhalf_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2xhalf_duplo_(3)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2xhalf_duplo_(3)_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x2xhalf_duplo_(4)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo_(1)_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x4xhalf_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)_(1)_(1)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['2x5xhalf_duplo_(1)_(1)_(1)_(2)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2_duplo'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2_duplo_(2)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2_duplo_(2)_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo_(1)_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo_(2)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['3x2xhalf_duplo_(2)_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo_(3)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo_(4)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2_duplo_(4)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x2x2_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x4xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x4xhalf_duplo_(2)'].geometry}
          material={materials['Plastic - Glossy (Yellow)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x4xhalf_duplo_(2)_(1)'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['4x4xhalf_duplo_(2)_(2)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['6x2_duplo'].geometry}
          material={materials['Plastic - Glossy (Pink)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['8x2xhalf_duplo'].geometry}
          material={materials['Plastic - Glossy (Purple)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['8x2xhalf_duplo_(1)'].geometry}
          material={materials['Plastic - Glossy (White)']}
          scale={10}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['8x2xhalf_duplo_(1)_(1)'].geometry}
          material={materials['Plastic - Glossy (Turquiose)']}
          scale={10}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./E_01.glb')
