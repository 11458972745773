import Body from './Body.jsx'
import Divider from './Divider.jsx'
import Experience from './Experience'
import Header from './Header.jsx'
import Profile from './Profile.jsx'
import { Canvas } from '@react-three/fiber'

export default function App() {
  return (
    <>
      <Header />
      {/* <Canvas
        className="canvas"
        shadows
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
          position: [-4, 3, 6]
        }}
        style={{
          height: '400px',
          width: '100%'
        }}
      >
        <Experience />
      </Canvas> */}
      <Divider />
      <Body />
      {/* <Profile /> */}
    </>
  )
}
