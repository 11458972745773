import Footer from './Footer'

export default function Body() {
  return (
    <>
      <div className=" grid text-center p-4 mainBody ">
        <img className="gifImg pb-3 img-fluid " src="./brb.webp" />

        <p className="pt-5 pb-4">
          I'm currently undergoing some modifications. Old me can be found{' '}
          <a href="https://eleredw7.wixsite.com/portfolio ">here</a>.
        </p>
      </div>
      <div className="verticalGradientReverse">
        <Footer />
      </div>
    </>
  )
}
