export default function Footer() {
  return (
    <div className=" grid text-center pt-4">
      <span className="fw-light">
        Eleanor Edwards •{' '}
        <a href="mailto:sayhi@eleanoredwards.co.uk">
          sayhi@eleanoredwards.co.uk
        </a>{' '}
        • 2024
      </span>
    </div>
  )
}
